<template>
  <div class="wrappe">
    <right-content>
      <ul>
        <li class="title" v-for="(item, index) in titleList" :key="index">
          {{ item.name }}
        </li>
      </ul>
      <ul class="content">
        <li class="title" v-for="(item, index) in titleList" :key="index">
          <el-input v-if="!item.select" :placeholder="item.placeholder" v-model="form[item.field]"></el-input>
          <el-select v-else-if="item.craft" v-model="form.craft" placeholder="请选择">
            <el-option
              v-for="item in optionCraft"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select v-else-if="item.politics_status" v-model="form.politics_status" placeholder="请选择">
            <el-option
              v-for="item in optionsPolitics"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </li>
      </ul>
      <div class="subBtn">
        <el-button @click="submit">提交</el-button>
      </div>
    </right-content>
  </div>
</template>

<script>
import RightContent from "../../commonViews/RightContent.vue";
import { isPhoneNum } from '@/utils/validate';
import {addInformation,userConfig} from '@/api';
export default {
  async created(){
    const res = await userConfig();
    const {craft,politics_status} = res.data.data;
    this.optionCraft = this.filterData(craft);
    this.optionsPolitics = this.filterData(politics_status);
  },
  props:{
    sentryId:Number
  },
  data() {
    return {
      optionCraft: [],
      optionsPolitics: [],
      form: {
        craft:"",
        politics_status:""
      },
      titleList: [
        {
          name: "姓名",
          field: "name",
          placeholder:"请输入姓名"
        },
        {
          name: "职务",
          field: "job",
          placeholder:"请输入职业"
        },
        {
          name: "工种",
          field: "craft",
          select: true,
          craft:true,
        },
        {
          name: "政治面貌",
          field: "politics_status",
          select: true,
          politics_status:true
        },
        {
          name: "联系电话",
          field: "phone",
          placeholder:"请输入联系电话"
        },
      ],
    };
  },
  methods:{
    filterData(data){
      return data.map(item => {
        return {
          label:item,
          value:item
        }
      })
    },
    async submit(){
      if(!this.sentryId) return
      try{
        if (!isPhoneNum(this.form.phone)) {
          throw new Error("手机格式错误");
        }
        const requestData = {
          type:4,
          value:this.form,
          sentry_id:this.sentryId
        }
        const res = await addInformation(requestData)

        if(res.status === 200) {
          this.$success("添加成功");
          this.$router.back()
        }
      }catch (e) {
        if (e instanceof Error) {
          this.$message(e.message);
        }
      }
    }
  },
  components: {
    RightContent
  },
};
</script>

<style lang="scss" scoped>
.wrappe {
  background-color: #f8f8f8;
  display: flex;
  height: 100%;
}
ul {
  height: 60px;
  background-color: #dcdcdc;
  display: flex;
  list-style-type: none;
  .title {
    width: 200px;
    text-align: center;
    line-height: 60px;
    border-right: 1px solid #f3f3f3;
    &:last-of-type {
      border-right: 0;
    }
  }
}
.content {
  border: 1px solid #dcdcdc;
  background-color: #ffff;
}
.subBtn{
  margin-top: 50px;
  display: flex;
  justify-content: center;
  button{
    width: 150px;
    background-color: #E32322;
    color: #fff;
  }
}
::v-deep .el-input__inner {
  width: 90%;
}
</style>
